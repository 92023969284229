import React, { Fragment } from 'react';
import { useHistory } from 'react-router-dom';
import colorsData from '../hex/colors-data';

const FamousColors = () => {
  const history = useHistory();
  const onClick = (c) => {
    let colorhex = c;
    if (colorhex.substring(0, 1) === '#') {
      colorhex = colorhex.substring(1);
    }
    let url = '/hex/' + colorhex;
    history.push(url);
  };
  return (
    <div className='card mt-3'>
      <div className='card-header'>
        <h2>Famous Colors</h2>
      </div>
      <div className='card-body'>
        <div className='row'>
          {colorsData.map((c, key) => (
            <Fragment key={key}>
              <div className='col-12 col-sm-6 col-md-4 col-lg-3 mb-3'>
                <div style={{ height: '150px', display: 'flex', flexDirection: 'column', cursor: 'pointer', backgroundColor: c.hex, alignItems: 'center', justifyContent: 'center', textAlign: 'center' }} onClick={() => onClick(c.hex)}>
                  <h4 className='d-block'>{c.searchKey}</h4>
                  <p>{c.hex}</p>
                </div>
              </div>
            </Fragment>
          ))}
        </div>
      </div>
    </div>
  );
};

export default FamousColors;
