import colorsData from './colors-data';
export const rgb2hex = (rgb) => {
  const { r, g, b } = rgb;
  try {
    var rHex = parseInt(r).toString(16).padStart(2, '0');
    var gHex = parseInt(g).toString(16).padStart(2, '0');
    var bHex = parseInt(b).toString(16).padStart(2, '0');
  } catch (e) {
    return false;
  }
  if (rHex.length > 2 || gHex.length > 2 || bHex.length > 2) return false;
  return '#' + rHex + gHex + bHex;
};

export const hex2rgb = (hex) => {
  var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result
    ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16),
      }
    : null;
};

export const rgb2hsl = (rgb) => {
  let { r, g, b } = rgb;
  r /= 255;
  g /= 255;
  b /= 255;
  let cmin = Math.min(r, g, b),
    cmax = Math.max(r, g, b),
    delta = cmax - cmin,
    h = 0,
    s = 0,
    l = 0;
  if (delta === 0) h = 0;
  else if (cmax === r) h = ((g - b) / delta) % 6;
  else if (cmax === g) h = (b - r) / delta + 2;
  else h = (r - g) / delta + 4;
  h = Math.round(h * 60);
  if (h < 0) h += 360;
  l = (cmax + cmin) / 2;
  s = delta === 0 ? 0 : delta / (1 - Math.abs(2 * l - 1));
  s = +(s * 100).toFixed(1);
  l = +(l * 100).toFixed(1);

  return { h, s, l };
};

export const hex2cmyk = (hex) => {
  var computedC = 0;
  var computedM = 0;
  var computedY = 0;
  var computedK = 0;

  hex = hex.charAt(0) === '#' ? hex.substring(1, 7) : hex;

  if (hex.length !== 6) {
    //alert('Invalid length of the input hex value!');
    return null;
  }
  if (/[0-9a-f]{6}/i.test(hex) !== true) {
    //alert('Invalid digits in the input hex value!');
    return null;
  }

  var r = parseInt(hex.substring(0, 2), 16);
  var g = parseInt(hex.substring(2, 4), 16);
  var b = parseInt(hex.substring(4, 6), 16);

  // BLACK
  if (r === 0 && g === 0 && b === 0) {
    computedK = 1;
    return { c: 0, m: 0, y: 0, k: 1 };
  }

  computedC = 1 - r / 255;
  computedM = 1 - g / 255;
  computedY = 1 - b / 255;

  var minCMY = Math.min(computedC, Math.min(computedM, computedY));

  computedC = (computedC - minCMY) / (1 - minCMY);
  computedM = (computedM - minCMY) / (1 - minCMY);
  computedY = (computedY - minCMY) / (1 - minCMY);
  computedK = minCMY;

  return { c: computedC, m: computedM, y: computedY, k: computedK };
};

export const rgb2hsv = (rgb) => {
  let { r, g, b } = rgb;
  r /= 255;
  g /= 255;
  b /= 255;

  var max = Math.max(r, g, b),
    min = Math.min(r, g, b);
  var h,
    s,
    v = max;

  var d = max - min;
  s = max === 0 ? 0 : d / max;

  if (max === min) {
    h = 0; // achromatic
  } else {
    switch (max) {
      case r:
        h = ((g - b) / d) % 6;
        break;
      case g:
        h = (b - r) / d + 2;
        break;
      case b:
        h = (r - g) / d + 4;
        break;
      default:
        h = (r - g) / d + 4;
        break;
    }
    h = Math.round(h * 60);
    if (h < 0) h += 360;
  }
  s = +(s * 100).toFixed(1);
  v = +(v * 100).toFixed(1);

  return { h, s, v };
};

export const hsv2rgb = (hsv) => {
  var rgb = {};
  let { h, s, v } = hsv;
  if (s === 0) {
    rgb.r = rgb.g = rgb.b = Math.round(v * 2.55);
  } else {
    h /= 60;
    s /= 100;
    v /= 100;
    let i = Math.floor(h);
    let f = h - i;
    let p = v * (1 - s);
    let q = v * (1 - s * f);
    let t = v * (1 - s * (1 - f));
    switch (i) {
      case 0:
        rgb.r = v;
        rgb.g = t;
        rgb.b = p;
        break;
      case 1:
        rgb.r = q;
        rgb.g = v;
        rgb.b = p;
        break;
      case 2:
        rgb.r = p;
        rgb.g = v;
        rgb.b = t;
        break;
      case 3:
        rgb.r = p;
        rgb.g = q;
        rgb.b = v;
        break;
      case 4:
        rgb.r = t;
        rgb.g = p;
        rgb.b = v;
        break;
      default:
        rgb.r = v;
        rgb.g = p;
        rgb.b = q;
    }
    rgb.r = Math.round(rgb.r * 255);
    rgb.g = Math.round(rgb.g * 255);
    rgb.b = Math.round(rgb.b * 255);
  }
  return rgb;
};

export const rgb2css = (rgb) => {
  const { r, g, b } = rgb;
  return 'rgb(' + r + ', ' + g + ', ' + b + ')';
};

const hex2hsv = (hex) => {
  return rgb2hsv(hex2rgb(hex));
};

export const compareRGB = (rgb1, rgb2) => {
  if (rgb1.r === rgb2.r && rgb1.g === rgb2.g && rgb1.b === rgb2.b) return true;
  return false;
};

const degrees = (a1, a2) => {
  let sum = a1 + a2;
  return sum > 360 ? sum - 360 : sum < 0 ? sum + 360 : sum;
};

const colorgen = (obj) => {
  let { hex, count, scope, rotation, type } = obj;
  count = !count ? 3 : count;
  rotation = !rotation ? 0 : rotation;
  type = !type ? 'hue' : type;
  scope = !scope && scope !== 0 ? 360 : scope;
  let hsv = hex2hsv(hex);
  let a1 = scope !== 0 && scope !== 360 ? scope / (count - 1) : scope / count;
  let hsvH = scope === 360 ? hsv.h : degrees(degrees(hsv.h, rotation), (-1 * scope) / 2);
  let output = [];
  for (let a = 0; count > a; a++) {
    let n = a1 * a;
    let tempHSV = {};
    switch (type) {
      case 'hue':
        tempHSV = {
          h: degrees(hsvH, n) === 360 ? 0 : degrees(hsvH, n),
          s: hsv.s,
          v: hsv.v,
        };
        output.push(hsv2rgb(tempHSV));
        break;
      case 'saturation':
        tempHSV = {
          h: hsv.h,
          s: n,
          v: hsv.v,
        };
        output.push(hsv2rgb(tempHSV));
        break;
      case 'value':
      case 'lightness':
      case 'brightness':
        tempHSV = {
          h: hsv.h,
          s: hsv.s,
          v: n,
        };
        output.push(hsv2rgb(tempHSV));
        break;
      default:
        break;
    }
  }
  return output;
};

export const complement = (hex) => {
  let scope = 0,
    rotation = 180,
    count = 1;
  return colorgen({ hex, count, scope, rotation });
};

export const triadic = (hex) => {
  return colorgen({ hex });
};

export const splitcomplement = (hex) => {
  let scope = 0,
    rotation = 150,
    count = 1,
    rgb = hex2rgb(hex),
    output = [];
  //output.push(colorgen({ hex, count, scope, rotation })[0]);
  //rotation = 150;
  output.push(colorgen({ hex, count, scope, rotation })[0]);
  output.push(rgb);
  rotation = 210;
  output.push(colorgen({ hex, count, scope, rotation })[0]);
  return output;
};

export const analogous = (hex) => {
  let scope = 0,
    rotation = 30,
    count = 1,
    rgb = hex2rgb(hex),
    output = [];
  output.push(colorgen({ hex, count, scope, rotation })[0]);
  output.push(rgb);
  rotation = -30;
  output.push(colorgen({ hex, count, scope, rotation })[0]);
  return output;
};

export const tetradic = (hex) => {
  let rgb = hex2rgb(hex);
  let output = [];
  // 1st color
  output.push(rgb);
  // 2nd color
  let rotation = 60,
    scope = 0,
    count = 1;
  output.push(colorgen({ hex, count, scope, rotation })[0]);
  // 3rd color
  rotation = 180;
  output.push(colorgen({ hex, count, scope, rotation })[0]);
  // 4th color
  rotation = 240;
  output.push(colorgen({ hex, count, scope, rotation })[0]);
  return output;
};

const shade = (percent, hex) => {
  var p = percent;
  var rgb = hex2rgb(hex);
  var r = Math.round,
    Psign = p < 0,
    t = Psign ? 0 : p * 255 ** 2;
  Psign = Psign ? 1 + p : 1 - p;
  return {
    r: r((Psign * rgb.r ** 2 + t) ** 0.5),
    g: r((Psign * rgb.g ** 2 + t) ** 0.5),
    b: r((Psign * rgb.b ** 2 + t) ** 0.5),
  };
};

export const lightshades = (hex) => {
  let output = [];
  for (var i = 0; i < 10; i++) output.push(shade(parseFloat(i / 10), hex));
  return output;
};

export const darkshades = (hex) => {
  let output = [];
  for (var i = 0; i < 10; i++) output.push(shade(parseFloat(i / -10), hex));
  return output;
};

export const findcolor = (hex) => {
  if (hex.substring(0, 1) !== '#') hex = '#' + hex;
  for (var i = 0; i < colorsData.length; i++) {
    if (colorsData[i].hex === hex) {
      return colorsData[i];
    }
  }
  return false;
};

export const searchcolor = (text) => {
  for (var i = 0; i < colorsData.length; i++) {
    if (colorsData[i].searchKey === text || colorsData[i].colorName === text) {
      return colorsData[i];
    }
  }
  return false;
};
