import React from 'react';
import PropTypes from 'prop-types';

const ColorPreview = ({ hex, bgHex }) => {
  hex = hex.toLowerCase();
  bgHex = bgHex.toLowerCase();
  if (hex === bgHex || hex === bgHex.substring(1)) return null;

  if (bgHex.substring(0, 1) !== '#') bgHex = '#' + bgHex;

  let colorName = 'Black';

  if (bgHex === '#ffffff') colorName = 'White';

  return (
    <div className='card mt-3'>
      <div className='card-header'>
        <h2>{`#${hex} Color Preview with ${colorName}`}</h2>
      </div>
      <div className='card-body p-5' style={{ backgroundColor: bgHex, border: '2px solid #' + hex }}>
        <p style={{ padding: 4 + 'rem', color: bgHex, backgroundColor: '#' + hex }}>
          Background of this text is a preview of the hex color <strong>#{hex}</strong>.
        </p>
        <p style={{ padding: 4 + 'rem', color: '#' + hex, backgroundColor: bgHex, border: '2px solid #' + hex }}>
          This string is displayed in hex color <strong>#{hex}</strong> on a {colorName.toLowerCase()} background.
        </p>
      </div>
    </div>
  );
};

ColorPreview.propTypes = {
  hex: PropTypes.string.isRequired,
  bgHex: PropTypes.string.isRequired,
};

export default ColorPreview;
