import React from 'react';
import PropTypes from 'prop-types';
import { compareRGB, complement, hex2rgb, rgb2hex, rgb2css } from './conversion-functions';
import { useHistory } from 'react-router-dom';

const ColorComplementary = ({ hex }) => {
  const history = useHistory();
  const onClick = (c) => {
    let colorhex = rgb2hex(c);
    if (colorhex.substring(0, 1) === '#') {
      colorhex = colorhex.substring(1);
    }
    let url = '/hex/' + colorhex;
    history.push(url);
  };
  const complementrgb = complement(hex)[0];
  const rgb = hex2rgb(hex);
  if (compareRGB(rgb, complementrgb)) return null;

  return (
    <div className='card mt-3'>
      <div className='card-header'>
        <h2>Complementary Colors with {'#' + hex}</h2>
      </div>
      <div className='card-body'>
        <p>Complementary colors are a pair of colors which are exactly opposite to each other on the color wheel. You can use this color scheme to make different elements extermely distinct from one another.</p>
        <div className='row'>
          <div className='col-sm-3 offset-sm-3 text-center'>
            <div style={{ height: 150 + 'px', backgroundColor: rgb2hex(rgb), position: 'relative', cursor: 'pointer' }} onClick={() => onClick(rgb)}></div>
            <div>{rgb2css(rgb)}</div>
            <div>{rgb2hex(rgb)}</div>
          </div>
          <div className='col-sm-3 text-center'>
            <div style={{ height: 150 + 'px', backgroundColor: rgb2css(complementrgb), position: 'relative', cursor: 'pointer' }} onClick={() => onClick(complementrgb)}></div>
            <div>{rgb2css(complementrgb)}</div>
            <div>{rgb2hex(complementrgb)}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

ColorComplementary.propTypes = {
  hex: PropTypes.string.isRequired,
};

export default ColorComplementary;
