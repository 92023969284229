import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useHistory } from 'react-router-dom';
import Navbar from '../layout/Navbar';
import ColorAnalogous from './ColorAnalogous';
import ColorComplementary from './ColorComplementary';
import ColorConversion from './ColorConversion';
import ColorInformation from './ColorInformation';
import ColorPreview from './ColorPreview';
import ColorShades from './ColorShades';
import ColorSplitComplementary from './ColorSplitComplementary';
import ColorTetradic from './ColorTetradic';
import ColorTints from './ColorTints';
import ColorTriadic from './ColorTriadic';
import { hex2rgb } from './conversion-functions';
import './Hex.css';

const Hex = ({ match }) => {
  const color = match.params.colorcode;
  const history = useHistory();
  const rgb = hex2rgb(color);

  const meta = 'Complete information for color #' + color + ' along with color schemes like complementary, analogous, triadic, split complementary, tetradic, tints and shades.';

  return (
    <section id='hex'>
      <div className='container pb-3'>
        <Navbar />
        <Helmet>
          <title>{`#${color} HEX Color - RGB(${rgb.r},${rgb.g},${rgb.b}) - Complete Information`}</title>
          <meta name='description' content={meta} />
        </Helmet>
        <ColorInformation hex={color} />
        <ColorPreview hex={color} bgHex='#000000' />
        <ColorPreview hex={color} bgHex='#FFFFFF' />
        <ColorConversion hex={color} />
        <ColorComplementary hex={color} />
        <ColorAnalogous hex={color} />
        <ColorTriadic hex={color} />
        <ColorSplitComplementary hex={color} />
        <ColorTetradic hex={color} />
        <ColorTints hex={color} />
        <ColorShades hex={color} />
      </div>
    </section>
  );
};

export default Hex;
