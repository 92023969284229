import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { findcolor, hex2cmyk, hex2rgb, rgb2hsl } from './conversion-functions';
import colorsData from './colors-data';

const ColorInformation = ({ hex }) => {
  const rgb = hex2rgb(hex);
  const cmyk = hex2cmyk(hex);
  const hsl = rgb2hsl(rgb);
  const colordata = findcolor(hex);

  return (
    <div className='card mt-3'>
      <div className='card-header'>
        <h1>{`#${hex} Color Information`}</h1>
      </div>
      <div className='card-body'>
        <p>
          Hex color code{' '}
          <Link to={`/hex/${hex}`}>
            <strong>#{hex}</strong>
          </Link>{' '}
          {colorsData && (
            <Fragment>
              also known as <strong>{colordata.searchKey}</strong>{' '}
            </Fragment>
          )}
          has RGB value <strong>rgb({`${rgb.r},${rgb.g},${rgb.b}`})</strong>. It is made up of {`${Math.round((rgb.r * 100) / 255)}%`} red, {`${Math.round((rgb.g * 100) / 255)}%`} green and {`${Math.round((rgb.b * 100) / 255)}%`} blue. In a CMYK (Cyan, Magenta, Yellow, Black) color space it is
          composed of {`${Math.round(cmyk.c * 100)}%`} cyan, {`${Math.round(cmyk.m * 100)}%`} magenta, {`${Math.round(cmyk.y * 100)}%`} yellow and {`${Math.round(cmyk.k * 100)}%`} black. In HSL (Hue, Saturation and Lightness) representation of colors, color is represented as hsl(
          {`${hsl.h}, ${hsl.s}%, ${hsl.l}%`}).
        </p>
      </div>
    </div>
  );
};

ColorInformation.propTypes = {
  hex: PropTypes.string.isRequired,
};

export default ColorInformation;
