import React from 'react';
import { Route, Switch } from 'react-router-dom';
import Hex from '../components/hex/Hex';
import Home from '../components/home/Home';

const Routes = () => {
  return (
    <Switch>
      <Route exact path='/' component={Home} />
      <Route exact path='/hex/:colorcode' component={Hex} />
    </Switch>
  );
};

export default Routes;
