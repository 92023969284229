import React from 'react';
import { Link } from 'react-router-dom';

const Navbar = () => {
  return (
    <nav className='navbar navbar-expand-lg navbar-light bg-light'>
      <Link className='navbar-brand mx-auto' to='/'>
        Colors
      </Link>
    </nav>
  );
};

export default Navbar;
