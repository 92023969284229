import React from 'react';
import PropTypes from 'prop-types';
import { hex2cmyk, hex2rgb, rgb2hsl, rgb2hsv } from './conversion-functions';

const ColorConversion = ({ hex }) => {
  const rgb = hex2rgb(hex);
  const cmyk = hex2cmyk(hex);
  const hsl = rgb2hsl(rgb);
  const hsv = rgb2hsv(rgb);
  return (
    <div className='card mt-3'>
      <div className='card-header'>
        <h2>{`#${hex} Color Conversion`}</h2>
      </div>
      <div className='card-body'>
        <table className='table table-striped'>
          <tbody>
            <tr>
              <th scope='row'>HEX (Hexadecimal) Value</th>
              <td>{hex}</td>
              <td>
                <code>#{hex}</code>
              </td>
            </tr>
            <tr>
              <th scope='row'>RGB Decimal Value</th>
              <td>{`${rgb.r}, ${rgb.g}, ${rgb.b}`}</td>
              <td>
                <code>rgb({`${rgb.r}, ${rgb.g}, ${rgb.b}`})</code>
              </td>
            </tr>
            <tr>
              <th scope='row'>CMYK Value</th>
              <td colSpan='2'>{`${Math.round((cmyk.c + Number.EPSILON) * 100) / 100}, ${Math.round((cmyk.m + Number.EPSILON) * 100) / 100}, ${Math.round((cmyk.y + Number.EPSILON) * 100) / 100}, ${Math.round((cmyk.k + Number.EPSILON) * 100) / 100}`}</td>
            </tr>
            <tr>
              <th scope='row'>HSL Value</th>
              <td>{`${hsl.h}°, ${hsl.s}, ${hsl.l}`}</td>
              <td>
                <code>{`hsl(${hsl.h}, ${hsl.s}%, ${hsl.l}%)`}</code>
              </td>
            </tr>
            <tr>
              <th scope='row'>HSV Value</th>
              <td colSpan='2'>{`${hsv.h}°, ${hsv.s}, ${hsv.v}`}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

ColorConversion.propTypes = {
  hex: PropTypes.string.isRequired,
};

export default ColorConversion;
