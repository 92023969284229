import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { lightshades, rgb2css, rgb2hex } from './conversion-functions';
import { useHistory } from 'react-router-dom';

const ColorTints = ({ hex }) => {
  const history = useHistory();
  const onClick = (c) => {
    let colorhex = rgb2hex(c);
    if (colorhex.substring(0, 1) === '#') {
      colorhex = colorhex.substring(1);
    }
    let url = '/hex/' + colorhex;
    history.push(url);
  };

  return (
    <div className='card mt-3'>
      <div className='card-header'>
        <h2>{'#' + hex} Color Tints</h2>
      </div>
      <div className='card-body'>
        <div className='row'>
          <div className='d-none d-md-block col-md-1' style={{ padding: 0 }}></div>
          {lightshades(hex).map((c, key) => (
            <Fragment key={key}>
              <div className='col-md-1 text-center' style={{ padding: 0, cursor: 'pointer' }} onClick={() => onClick(c)}>
                <div style={{ height: 150 + 'px', backgroundColor: rgb2css(c), display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                  <span>{rgb2hex(c)}</span>
                </div>
              </div>
            </Fragment>
          ))}
        </div>
      </div>
    </div>
  );
};

ColorTints.propTypes = {
  hex: PropTypes.string.isRequired,
};

export default ColorTints;
