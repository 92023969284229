import React, { Fragment, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useHistory } from 'react-router-dom';
import { searchcolor } from '../hex/conversion-functions';
import { AlertMsg } from '../layout/AlertMsg';
import Navbar from '../layout/Navbar';
import FamousColors from './FamousColors';

const Home = () => {
  const searchPlaceHolder = 'Enter any color value (e.g., #ff00ff or aqua marine or red)';
  const history = useHistory();
  const [formData, setFormData] = useState({
    colorCode: '',
    alert: null,
  });

  const { colorCode, alert } = formData;

  const onSubmit = (e) => {
    e.preventDefault();

    if (!colorCode || !colorCode.trim()) {
      setAlert({ type: 'danger', msg: 'Please enter color code' });
      return;
    }
    var color = validateColor(colorCode);
    if (!color) {
      setAlert({ type: 'danger', msg: 'Invalid color code' });
      return;
    }
    history.push('/hex/' + color);
  };

  const validateColor = (color) => {
    let colorfound = searchcolor(color);
    if (colorfound !== false && colorfound.hex) {
      return colorfound.hex.substring(1);
    }
    var shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
    color = color.replace(shorthandRegex, function (m, r, g, b) {
      return r + r + g + g + b + b;
    });
    var validHEXInput = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(color);
    if (!validHEXInput) {
      return false;
    }
    return color;
  };

  const setAlert = (alert) => {
    setFormData({
      ...formData,
      alert: {
        type: alert.type,
        msg: alert.msg,
      },
    });
  };

  const onChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const title = 'Colors - Get Complete Color information with various schemes and shades';
  const meta =
    'Free online tool for searching color related information. Just use the above search box or pick your favorite color from below and tool will provide all relevant information which will help you make better designs. This online tool provides you details for various color schemes such as complementary scheme, analogous scheme, triadic scheme, split complementary scheme, tetradic scheme, etc. It also display various shades and tints for the selected color.';

  return (
    <Fragment>
      <Helmet>
        <title>{title}</title>
        <meta name='description' content={meta} />
      </Helmet>
      <Navbar />
      <div className='container'>
        <div className='card mt-3 p-3'>
          <AlertMsg alert={alert} />
          <form onSubmit={onSubmit}>
            <div className='form-row form-group p-2'>
              <div className='col-9'>
                <input type='text' className='form-control' id='colorCode' name='colorCode' placeholder={searchPlaceHolder} value={colorCode} onChange={onChange} />
              </div>
              <div className='col-3'>
                <button type='submit' className='btn btn-primary mb-2'>
                  Search
                </button>
              </div>
            </div>
          </form>
        </div>
        <div className='card mt-3 p-3'>
          <p>
            Free online tool for searching color related information. Just use the above search box or pick your favorite color from below and tool will provide all relevant information which will help you make better designs. This online tool provides you details for various color schemes such as
            complementary scheme, analogous scheme, triadic scheme, split complementary scheme, tetradic scheme, etc. It also display various shades and tints for the selected color.
          </p>
        </div>
        <FamousColors />
      </div>
    </Fragment>
  );
};

export default Home;
