const colorsData = [
  { colorName: 'aliceblue', hex: '#f0f8ff', searchKey: 'alice blue' },
  { colorName: 'antiquewhite', hex: '#faebd7', searchKey: 'antique white' },
  { colorName: 'aqua', hex: '#00ffff', searchKey: 'aqua' },
  { colorName: 'aquamarine', hex: '#7fffd4', searchKey: 'aqua marine' },
  { colorName: 'azure', hex: '#f0ffff', searchKey: 'azure' },
  { colorName: 'beige', hex: '#f5f5dc', searchKey: 'beige' },
  { colorName: 'bisque', hex: '#ffe4c4', searchKey: 'bisque' },
  { colorName: 'black', hex: '#000000', searchKey: 'black' },
  { colorName: 'blanchedalmond', hex: '#ffebcd', searchKey: 'blanched almond' },
  { colorName: 'blue', hex: '#0000ff', searchKey: 'blue' },
  { colorName: 'blueviolet', hex: '#8a2be2', searchKey: 'blue violet' },
  { colorName: 'brown', hex: '#a52a2a', searchKey: 'brown' },
  { colorName: 'burlywood', hex: '#deb887', searchKey: 'burly wood' },
  { colorName: 'cadetblue', hex: '#5f9ea0', searchKey: 'cadet blue' },
  { colorName: 'chartreuse', hex: '#7fff00', searchKey: 'chartreuse' },
  { colorName: 'chocolate', hex: '#d2691e', searchKey: 'chocolate' },
  { colorName: 'coral', hex: '#ff7f50', searchKey: 'coral' },
  { colorName: 'cornflowerblue', hex: '#6495ed', searchKey: 'cornflower blue' },
  { colorName: 'cornsilk', hex: '#fff8dc', searchKey: 'corn silk' },
  { colorName: 'crimson', hex: '#dc143c', searchKey: 'crimson' },
  { colorName: 'cyan', hex: '#00ffff', searchKey: 'cyan' },
  { colorName: 'darkblue', hex: '#00008b', searchKey: 'dark blue' },
  { colorName: 'darkcyan', hex: '#008b8b', searchKey: 'dark cyan' },
  { colorName: 'darkgoldenrod', hex: '#b8860b', searchKey: 'dark goldenrod' },
  { colorName: 'darkgray', hex: '#a9a9a9', searchKey: 'dark gray' },
  { colorName: 'darkgreen', hex: '#006400', searchKey: 'dark green' },
  { colorName: 'darkkhaki', hex: '#bdb76b', searchKey: 'dark khaki' },
  { colorName: 'darkmagenta', hex: '#8b008b', searchKey: 'dark magenta' },
  { colorName: 'darkolivegreen', hex: '#556b2f', searchKey: 'dark olive green' },
  { colorName: 'darkorange', hex: '#ff8c00', searchKey: 'dark orange' },
  { colorName: 'darkorchid', hex: '#9932cc', searchKey: 'dark orchid' },
  { colorName: 'darkred', hex: '#8b0000', searchKey: 'dark red' },
  { colorName: 'darksalmon', hex: '#e9967a', searchKey: 'dark salmon' },
  { colorName: 'darkseagreen', hex: '#8fbc8f', searchKey: 'dark sea green' },
  { colorName: 'darkslateblue', hex: '#483d8b', searchKey: 'dark slate blue' },
  { colorName: 'darkslategray', hex: '#2f4f4f', searchKey: 'dark slate gray' },
  { colorName: 'darkturquoise', hex: '#00ced1', searchKey: 'dark turquoise' },
  { colorName: 'darkviolet', hex: '#9400d3', searchKey: 'dark violet' },
  { colorName: 'deeppink', hex: '#ff1493', searchKey: 'deep pink' },
  { colorName: 'deepskyblue', hex: '#00bfff', searchKey: 'deep sky blue' },
  { colorName: 'dimgray', hex: '#696969', searchKey: 'dim gray' },
  { colorName: 'dodgerblue', hex: '#1e90ff', searchKey: 'dodger blue' },
  { colorName: 'firebrick', hex: '#b22222', searchKey: 'fire brick' },
  { colorName: 'floralwhite', hex: '#fffaf0', searchKey: 'floral white' },
  { colorName: 'forestgreen', hex: '#228b22', searchKey: 'forest green' },
  { colorName: 'fuchsia', hex: '#ff00ff', searchKey: 'fuchsia' },
  { colorName: 'gainsboro', hex: '#dcdcdc', searchKey: 'gainsboro' },
  { colorName: 'ghostwhite', hex: '#f8f8ff', searchKey: 'ghost white' },
  { colorName: 'gold', hex: '#ffd700', searchKey: 'gold' },
  { colorName: 'goldenrod', hex: '#daa520', searchKey: 'goldenrod' },
  { colorName: 'gray', hex: '#808080', searchKey: 'gray' },
  { colorName: 'green', hex: '#008000', searchKey: 'green' },
  { colorName: 'greenyellow', hex: '#adff2f', searchKey: 'green yellow' },
  { colorName: 'honeydew', hex: '#f0fff0', searchKey: 'honey dew' },
  { colorName: 'hotpink', hex: '#ff69b4', searchKey: 'hotpink' },
  { colorName: 'indianred', hex: '#cd5c5c', searchKey: 'indian red' },
  { colorName: 'indigo', hex: '#4b0082', searchKey: 'indigo' },
  { colorName: 'ivory', hex: '#fffff0', searchKey: 'ivory' },
  { colorName: 'khaki', hex: '#f0e68c', searchKey: 'khaki' },
  { colorName: 'lavender', hex: '#e6e6fa', searchKey: 'lavender' },
  { colorName: 'lavenderblush', hex: '#fff0f5', searchKey: 'lavender blush' },
  { colorName: 'lawngreen', hex: '#7cfc00', searchKey: 'lawn green' },
  { colorName: 'lemonchiffon', hex: '#fffacd', searchKey: 'lemon chiffon' },
  { colorName: 'lightblue', hex: '#add8e6', searchKey: 'light blue' },
  { colorName: 'lightcoral', hex: '#f08080', searchKey: 'light coral' },
  { colorName: 'lightcyan', hex: '#e0ffff', searchKey: 'light cyan' },
  { colorName: 'lightgoldenrodyellow', hex: '#fafad2', searchKey: 'light goldenrod yellow' },
  { colorName: 'lightgrey', hex: '#d3d3d3', searchKey: 'light grey' },
  { colorName: 'lightgreen', hex: '#90ee90', searchKey: 'light green' },
  { colorName: 'lightpink', hex: '#ffb6c1', searchKey: 'light pink' },
  { colorName: 'lightsalmon', hex: '#ffa07a', searchKey: 'light salmon' },
  { colorName: 'lightseagreen', hex: '#20b2aa', searchKey: 'light sea green' },
  { colorName: 'lightskyblue', hex: '#87cefa', searchKey: 'light sky blue' },
  { colorName: 'lightslategray', hex: '#778899', searchKey: 'light slate gray' },
  { colorName: 'lightsteelblue', hex: '#b0c4de', searchKey: 'light steel blue' },
  { colorName: 'lightyellow', hex: '#ffffe0', searchKey: 'light yellow' },
  { colorName: 'lime', hex: '#00ff00', searchKey: 'lime' },
  { colorName: 'limegreen', hex: '#32cd32', searchKey: 'lime green' },
  { colorName: 'linen', hex: '#faf0e6', searchKey: 'linen' },
  { colorName: 'magenta', hex: '#ff00ff', searchKey: 'magenta' },
  { colorName: 'maroon', hex: '#800000', searchKey: 'maroon' },
  { colorName: 'mediumaquamarine', hex: '#66cdaa', searchKey: 'medium aquamarine' },
  { colorName: 'mediumblue', hex: '#0000cd', searchKey: 'medium blue' },
  { colorName: 'mediumorchid', hex: '#ba55d3', searchKey: 'medium orchid' },
  { colorName: 'mediumpurple', hex: '#9370d8', searchKey: 'medium purple' },
  { colorName: 'mediumseagreen', hex: '#3cb371', searchKey: 'medium sea green' },
  { colorName: 'mediumslateblue', hex: '#7b68ee', searchKey: 'medium slate blue' },
  { colorName: 'mediumspringgreen', hex: '#00fa9a', searchKey: 'medium spring green' },
  { colorName: 'mediumturquoise', hex: '#48d1cc', searchKey: 'medium turquoise' },
  { colorName: 'mediumvioletred', hex: '#c71585', searchKey: 'medium violetred' },
  { colorName: 'midnightblue', hex: '#191970', searchKey: 'midnight blue' },
  { colorName: 'mintcream', hex: '#f5fffa', searchKey: 'mint cream' },
  { colorName: 'mistyrose', hex: '#ffe4e1', searchKey: 'misty rose' },
  { colorName: 'moccasin', hex: '#ffe4b5', searchKey: 'moccasin' },
  { colorName: 'navajowhite', hex: '#ffdead', searchKey: 'navajo white' },
  { colorName: 'navy', hex: '#000080', searchKey: 'navy' },
  { colorName: 'oldlace', hex: '#fdf5e6', searchKey: 'oldlace' },
  { colorName: 'olive', hex: '#808000', searchKey: 'olive' },
  { colorName: 'olivedrab', hex: '#6b8e23', searchKey: 'olive drab' },
  { colorName: 'orange', hex: '#ffa500', searchKey: 'orange' },
  { colorName: 'orangered', hex: '#ff4500', searchKey: 'orange red' },
  { colorName: 'orchid', hex: '#da70d6', searchKey: 'orchid' },
  { colorName: 'palegoldenrod', hex: '#eee8aa', searchKey: 'pale goldenrod' },
  { colorName: 'palegreen', hex: '#98fb98', searchKey: 'pale green' },
  { colorName: 'paleturquoise', hex: '#afeeee', searchKey: 'pale turquoise' },
  { colorName: 'palevioletred', hex: '#d87093', searchKey: 'pale violet red' },
  { colorName: 'papayawhip', hex: '#ffefd5', searchKey: 'papaya whip' },
  { colorName: 'peachpuff', hex: '#ffdab9', searchKey: 'peach puff' },
  { colorName: 'peru', hex: '#cd853f', searchKey: 'peru' },
  { colorName: 'pink', hex: '#ffc0cb', searchKey: 'pink' },
  { colorName: 'plum', hex: '#dda0dd', searchKey: 'plum' },
  { colorName: 'powderblue', hex: '#b0e0e6', searchKey: 'powder blue' },
  { colorName: 'purple', hex: '#800080', searchKey: 'purple' },
  { colorName: 'rebeccapurple', hex: '#663399', searchKey: 'rebecca purple' },
  { colorName: 'red', hex: '#ff0000', searchKey: 'red' },
  { colorName: 'rosybrown', hex: '#bc8f8f', searchKey: 'rosy brown' },
  { colorName: 'royalblue', hex: '#4169e1', searchKey: 'royal blue' },
  { colorName: 'saddlebrown', hex: '#8b4513', searchKey: 'saddle brown' },
  { colorName: 'salmon', hex: '#fa8072', searchKey: 'salmon' },
  { colorName: 'sandybrown', hex: '#f4a460', searchKey: 'sandy brown' },
  { colorName: 'seagreen', hex: '#2e8b57', searchKey: 'sea green' },
  { colorName: 'seashell', hex: '#fff5ee', searchKey: 'sea shell' },
  { colorName: 'sienna', hex: '#a0522d', searchKey: 'sienna' },
  { colorName: 'silver', hex: '#c0c0c0', searchKey: 'silver' },
  { colorName: 'skyblue', hex: '#87ceeb', searchKey: 'sky blue' },
  { colorName: 'slateblue', hex: '#6a5acd', searchKey: 'slate blue' },
  { colorName: 'slategray', hex: '#708090', searchKey: 'slate gray' },
  { colorName: 'snow', hex: '#fffafa', searchKey: 'snow' },
  { colorName: 'springgreen', hex: '#00ff7f', searchKey: 'spring green' },
  { colorName: 'steelblue', hex: '#4682b4', searchKey: 'steel blue' },
  { colorName: 'tan', hex: '#d2b48c', searchKey: 'tan' },
  { colorName: 'teal', hex: '#008080', searchKey: 'teal' },
  { colorName: 'thistle', hex: '#d8bfd8', searchKey: 'thistle' },
  { colorName: 'tomato', hex: '#ff6347', searchKey: 'tomato' },
  { colorName: 'turquoise', hex: '#40e0d0', searchKey: 'turquoise' },
  { colorName: 'violet', hex: '#ee82ee', searchKey: 'violet' },
  { colorName: 'wheat', hex: '#f5deb3', searchKey: 'wheat' },
  { colorName: 'white', hex: '#ffffff', searchKey: 'white' },
  { colorName: 'whitesmoke', hex: '#f5f5f5', searchKey: 'white smoke' },
  { colorName: 'yellow', hex: '#ffff00', searchKey: 'yellow' },
  { colorName: 'yellowgreen', hex: '#9acd32', searchKey: 'yellow green' },
];
export default colorsData;
