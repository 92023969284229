import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { rgb2css, rgb2hex, tetradic } from './conversion-functions';
import { useHistory } from 'react-router-dom';

const ColorTetradic = ({ hex }) => {
  const history = useHistory();
  const onClick = (c) => {
    let colorhex = rgb2hex(c);
    if (colorhex.substring(0, 1) === '#') {
      colorhex = colorhex.substring(1);
    }
    let url = '/hex/' + colorhex;
    history.push(url);
  };
  return (
    <div className='card mt-3'>
      <div className='card-header'>
        <h2>Tetradic Colors with {'#' + hex}</h2>
      </div>
      <div className='card-body'>
        <div className='row'>
          {tetradic(hex).map((c, key) => (
            <Fragment key={key}>
              <div className='col-sm-3 text-center'>
                <div style={{ height: 150 + 'px', backgroundColor: rgb2css(c), position: 'relative', cursor: 'pointer' }} onClick={() => onClick(c)}></div>
                <div>{rgb2css(c)}</div>
                <div>{rgb2hex(c)}</div>
              </div>
            </Fragment>
          ))}
        </div>
      </div>
    </div>
  );
};

ColorTetradic.propTypes = {
  hex: PropTypes.string.isRequired,
};

export default ColorTetradic;
